/* eslint-disable no-unused-vars */
import { GET_ALL_USERS, GET_USER_ID, GET_PERMISSION_ID } from './actionTypes'
import Request from 'utils/Request'
import { HandleSuccessRes, HandleErrorRes, redirectUnAuthApi, HandleValidationsError } from 'store/actions'
import History from 'utils/history'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

let urlPath = '/users'

export const getAllUsers = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath + `?page=${pageNo}&title=${filterData.username?.trim() || ''}`
        : urlPath + `?page=${pageNo}`,
    )
      .then(({ data }) => {
        data.data = data.data.map(item => ({ id: item.id, ...item.user_data }))
        return dispatch({
          type: GET_ALL_USERS,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const addPermissionsUser = (t, payload) => {
  return dispatch => {
    return Request.post('/roles', payload)
      .then(({ data }) => {
        return {
          type: '',
          payload: data,
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const createUser = (t, payload) => {
  return dispatch => {
    return Request.post(urlPath, payload).then(({ data }) => {
      HandleSuccessRes(t('success'), t('successfully added'))
      History.push('/users')
      return {
        type: '',
        payload: '',
      }
    })
    // .catch(error => {
    //   console.log('u catch')

    //   if (error?.response?.data?.errors) {
    //     HandleValidationsError(t, error.response.data.errors)
    //   } else {
    //     // HandleErrorRes(t('failed'), t('add failed'))
    //   }
    //   return {
    //     type: '',
    //     payload: '',
    //   }
    // })
  }
}

export const ViewUser = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_USER_ID,
        payload: data.data,
      })
    })
  }
}

export const ViewPermissionsUser = id => {
  return dispatch => {
    return Request.get(`/roles/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_PERMISSION_ID,
        payload: data.data,
      })
    })
  }
}

export const editPermissionsUser = (t, id, payload) => {
  return dispatch => {
    return Request.put(`/roles/${id}`, payload)
      .then(({ data }) => {
        return {
          type: '',
          payload: data,
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const editUser = (t, id, payload) => {
  return dispatch => {
    return Request.put(urlPath + `/${id}`, payload).then(({ data }) => {
      HandleSuccessRes(t('success'), t('successfully updated'))
      History.push('/users')
      return {
        type: '',
        payload: '',
      }
    })
    // .catch(error => {
    //   if (error?.response?.data?.errors) {
    //     HandleValidationsError(t, error.response.data.errors)
    //   } else {
    //     HandleErrorRes(t('failed'), t('update failed'))
    //   }
    //   return {
    //     type: '',
    //     payload: '',
    //   }
    // })
  }
}

export const deleteUser = (t, id, pageNo, filterData) => {
  return dispatch => {
    return Request.delete(urlPath + `/${id}`)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully deleted'))
        dispatch(getAllUsers(pageNo, filterData))
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        HandleErrorRes(t('failed'), t('delete failed'))
        return {
          type: '',
          payload: '',
        }
      })
  }
}

/* Handle Delete Data Row */
export const handleDeleteUserRow = (id, t, pageNo, filterData) => {
  return dispatch => {
    Modal.confirm({
      title: t('are you sure delete this'),
      icon: <ExclamationCircleOutlined />,
      content: t('you wont be able to revert this'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        dispatch(deleteUser(t, id, pageNo, filterData))
      },
    })
  }
}
