import React from 'react'
import PropTypes from 'prop-types'
import 'antd/dist/antd.css'
import { Drawer } from 'antd'
import SideBar from './SideBar'
import { useTranslation } from 'react-i18next'

const DrawerSidebar = ({ setVisible, visible, collapsed, onCollapse, width, openKeys, onOpenChange }) => {
  const {
    i18n: { language },
  } = useTranslation()

  const onClose = () => {
    setVisible(false)
  }

  return (
    <Drawer placement={language === 'ar' ? 'right' : 'left'} onClose={onClose} visible={visible}>
      <SideBar
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={width}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </Drawer>
  )
}

DrawerSidebar.propTypes = {
  setVisible: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  width: PropTypes.number,
  openKeys: PropTypes.string.isRequired,
  onOpenChange: PropTypes.func.isRequired,
}

export default DrawerSidebar
