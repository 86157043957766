import { GET_APPEARANCE_ID } from './actionTypes'

const initialState = {
  data: undefined,
  singleRow: {},
  last_page: null,
  total: null,
  per_page: 10,
  headerHieght: 0,
  footerHieght: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_APPEARANCE_ID:
      return {
        ...state,
        singleRow: action.payload,
        headerHieght: action.payload.header_height * 37.7952755906,
        footerHieght: action.payload.footer_height * 37.7952755906,
      }

    default:
      state = { ...state }
      break
  }
  return state
}
