/* eslint-disable no-unused-vars */
import { GET_APPEARANCE_ID } from './actionTypes'
import Request from 'utils/Request'
import History from 'utils/history'
import { HandleSuccessRes, HandleErrorRes, redirectUnAuthApi, HandleValidationsError } from 'store/actions'

let urlPath = '/configurations'

export const ViewAppearance = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`)
      .then(({ data }) => {
        return dispatch({
          type: GET_APPEARANCE_ID,
          payload: data.data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const editAppearance = (t, id, payload) => {
  return dispatch => {
    return Request.post(urlPath + `/${id}`, payload)
      .then(() => {
        HandleSuccessRes(t('success'), t('successfully updated'))
        History.push('/dashboard')
        dispatch(ViewAppearance(1))
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}
