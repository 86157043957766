import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
//fc
import SideBar from './SideBar'
import DrawerSidebar from './DrawerSidebar'
import TopHeader from './TopHeader'
import Footer from './Footer'
//ant-des
import { Layout } from 'antd'

const { Content } = Layout

//global modals
import MedicalReportModal from '../Invoices/MedicalReportModal'
import PrescriptionModal from '../Invoices/PrescriptionModal'
import TransactionsInvoice from '../Invoices/TtransactionsInvoice'
import TechSupport from './Footer/TechSupport'

function AuthLayout({
  children,
  invoiceModalStatus,
  invoiceModalData,
  prescriptionModalData,
  prescriptionModalStatus,
  medicalReportModalStatus,
  medicalReportModalData,
  appearanceSetting,
}) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  /* --------------------------------------- */

  const [collapsed, setCollapsed] = useState(false)

  const onCollapse = () => {
    setCollapsed(current => !current)
  }

  /* --------------------------------------- */

  const location = useLocation()

  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  useEffect(() => {
    const currentage = capitalizeFirstLetter(location.pathname)
    document.title = `${currentage} | CRM`
    return () => {}
  }, [])

  /* --------------------------------------- */

  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(hasWindow ? window.innerWidth : null)
  const updateWidth = () => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [hasWindow])

  /* --------------------------------------- */
  const [openKeys, setOpenKeys] = useState([location.pathname.split('/')[1]])

  // submenu keys of first level
  const rootSubmenuKeys = ['configurations']

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  /* --------------------------------------- */

  const [OpenAlertLicens, setOpenAlertLicens] = useState(false)
  const [expiredTime, setexpiredTime] = useState(null)

  useEffect(() => {
    if (appearanceSetting?.license === false) {
      setOpenAlertLicens(true)
      setexpiredTime(appearanceSetting?.expired)
    }
  }, [appearanceSetting])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {OpenAlertLicens && (
        <TechSupport
          open={OpenAlertLicens}
          setOpen={setOpenAlertLicens}
          licensMessage={
            t('your license has been expired , please renew your license before deadline time expirat') +
            ' : ' +
            expiredTime
          }
        />
      )}
      {width < 576 ? (
        <TopHeader setVisible={setVisible} onOpenChange={onOpenChange} openKeys={openKeys} />
      ) : (
        <SideBar
          collapsed={collapsed}
          onCollapse={onCollapse}
          width={width}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      )}
      <DrawerSidebar
        visible={visible}
        setVisible={setVisible}
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={width}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />

      <Layout className="site-layout" style={{ marginTop: width < 576 ? 65 : 0 }}>
        <Content style={{ margin: '0 8px' }}>
          <div style={{ padding: width < 576 ? 0 : '0 8px' }}>{children}</div>
        </Content>
        <Footer />
      </Layout>
      {medicalReportModalStatus && (
        <MedicalReportModal
          medicalReportModalStatus={medicalReportModalStatus}
          medicalReportModalData={medicalReportModalData}
        />
      )}
      {prescriptionModalStatus && (
        <PrescriptionModal
          prescriptionModalData={prescriptionModalData}
          prescriptionModalStatus={prescriptionModalStatus}
        />
      )}
      {invoiceModalStatus && (
        <TransactionsInvoice invoiceModalData={invoiceModalData} invoiceModalStatus={invoiceModalStatus} />
      )}
    </Layout>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  invoiceModalStatus: PropTypes.bool,
  invoiceModalData: PropTypes.object.isRequired,
  prescriptionModalData: PropTypes.bool,
  prescriptionModalStatus: PropTypes.object.isRequired,
  medicalReportModalStatus: PropTypes.bool,
  medicalReportModalData: PropTypes.object.isRequired,
  appearanceSetting: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  invoiceModalStatus: state.Layout.invoiceModalStatus,
  invoiceModalData: state.Layout.invoiceModalData,
  prescriptionModalData: state.Layout.prescriptionModalData,
  prescriptionModalStatus: state.Layout.prescriptionModalStatus,
  medicalReportModalStatus: state.Layout.medicalReportModalStatus,
  medicalReportModalData: state.Layout.medicalReportModalData,
  appearanceSetting: state.Appearance.singleRow,
})

export default connect(mapStateToProps)(AuthLayout)
