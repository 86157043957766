import { GET_ALL_COUNTRIES, GET_ALL_GOVERNORATES, GET_ALL_AREAS } from './actionTypes'

const initialState = {
  countries: undefined,
  governorates: undefined,
  areas: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }

    case GET_ALL_GOVERNORATES:
      return {
        ...state,
        governorates: action.payload,
      }

    case GET_ALL_AREAS:
      return {
        ...state,
        areas: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}
