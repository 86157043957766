import { LOGIN_AUTH } from './actionTypes'

const initialState = {
  account_role: '',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'type':
      return { ...state, ...payload }

    default:
      return state
  }
}
