import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Typography, Card } from 'antd'
import { setModalInvoiceData, ViewAppearance } from 'store/actions'
import { SEED_REDUCER_DATA } from 'store/Layout/actionTypes'
import { BASE_URL } from 'utils/constants'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import defaultlogo from 'assets/images/logo-icon.svg'
import { clculateAgeBirthDate } from 'utils/helpers'

const { Title, Text } = Typography

const MedicalReportModal = ({
  prescriptionModalData,
  appearanceSetting,
  ViewAppearance,
  headerHieght,
  footerHieght,
}) => {
  const { prescriptionModalStatus } = useSelector(state => state.Layout)
  const {
    t,
    i18n: { language },
  } = useTranslation('', { i18n })
  const dispatch = useDispatch()

  const logo = appearanceSetting?.media?.logo
    ? `${BASE_URL}${appearanceSetting.media.logo?.url}`
    : defaultlogo

  /* call Appearance view api to return data */
  useEffect(() => {
    ViewAppearance(1)
    return () => {}
  }, [])

  const handleCancel = () => {
    dispatch(
      setModalInvoiceData({
        type: SEED_REDUCER_DATA,
        prescriptionModalStatus: false,
      }),
    )
  }

  window.onafterprint = function () {
    console.log('closed')
    handleCancel()
  }

  useEffect(async () => {
    if (prescriptionModalStatus) {
      await ViewAppearance(1)
      await window.print()
    }
    return () => {
      handleCancel()
    }
  }, [prescriptionModalStatus])

  return (
    <div id="section-to-print" className="report__modal prescription-print">
      <div>
        {appearanceSetting?.custom_printing === false && (
          <div className="fixed-top" style={{ top: 10, left: 1 }}>
            <Row>
              <Col xs={12}>
                <div>
                  <Title level={5} className="my-0 text-primary">
                    {language === 'en' ? appearanceSetting?.name_en : appearanceSetting?.name_ar}
                  </Title>
                  <span className="my-0 text-muted font-size-13">
                    {language === 'en'
                      ? appearanceSetting?.scpeciality_en
                      : appearanceSetting?.scpeciality_ar}
                  </span>
                </div>
              </Col>
              <Col xs={4}></Col>

              <Col xs={8}>
                <div className="doctor-logo-wrapper">
                  <div className="name-wrapper">
                    <img src={logo} />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="">
              <Col xs={24}>
                <div
                  className="px-3 py-2 patient-data font-size-14"
                  style={{ border: '2px solid var(--bs-primary)', borderRadius: 10 }}>
                  <div className="d-flex align-items-center">
                    <b className="mb-0 me-2">{t('patient name')}</b>
                    <span className="px-2">{prescriptionModalData?.patient?.name}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <b className="mb-0 me-2">{t('age')}</b>
                    <span className="px-2">
                      {String(clculateAgeBirthDate(t, prescriptionModalData?.patient?.birthdate))}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <b className="mb-0 me-2">{t('date')}</b>
                    <span className="px-2">{moment(prescriptionModalData?.date).format('DD/MM/YYYY')}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {appearanceSetting?.custom_printing === false && (
          <div
            style={{
              border: '2px solid var(--bs-primary)',
              borderRadius: 10,
              position: 'fixed',
              width: '99.5%',
              height: '75%',
              top: 160,
              left: 1,
            }}></div>
        )}
        <table className="h-100 w-100">
          <thead>
            <tr>
              <td>
                {/* <!--place holder for the fixed-position header--> */}
                <div
                  className="page-header-space"
                  style={{
                    height: appearanceSetting?.custom_printing ? headerHieght : 170,
                  }}>
                  &nbsp;
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* <!--*** CONTENT HERE ***--> */}
                <div
                  style={{
                    // height: '100%',
                    borderRadius: 10,
                    // width: '95%',
                    padding: '10px 40px',
                  }}>
                  <Row
                    className="mb-3 text-center font-size-14 prescription-header"
                    style={{ borderBottom: '2px solid gray' }}>
                    <Col xs={6}>{t('drug')}</Col>
                    <Col xs={6}>{t('dose')}</Col>
                    <Col xs={8}>{t('route of administration')}</Col>
                    <Col xs={4}>{t('period')}</Col>
                  </Row>

                  {prescriptionModalData?.drugs?.length > 0 &&
                    prescriptionModalData.drugs.map(row => (
                      <Row key={row.id} className="mb-3 text-center font-size-13 prescription-data">
                        <Col xs={6}>{row?.title}</Col>
                        <Col xs={6}>{row?.dose}</Col>
                        <Col xs={8}>{row?.route}</Col>
                        <Col xs={4}>{row?.duration}</Col>
                      </Row>
                    ))}
                  <Row className="my-4 pt-3 text-center font-size-13 prescription-note">
                    <Col xs={24}>
                      <Text>{prescriptionModalData?.prescription_note}</Text>
                    </Col>
                  </Row>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                {/* <!--place holder for the fixed-position footer--> */}
                <div
                  className="page-footer-space "
                  style={{
                    height: appearanceSetting?.custom_printing ? footerHieght : 170,
                  }}></div>
              </td>
            </tr>
          </tfoot>
        </table>

        {appearanceSetting?.custom_printing === false && (
          <div
            className="modal-footer fixed-bottom "
            style={{ backgroundColor: '#fff', borderTop: '2px solid var(--bs-primary)' }}>
            <Row style={{ direction: 'ltr', width: '100%' }}>
              <Col xs={12} className="text-left">
                <div style={{ direction: 'ltr' }}>{appearanceSetting?.footer_en}</div>
              </Col>
              <Col xs={12} className="text-right">
                <div style={{ direction: 'rtl' }}>{appearanceSetting?.footer_ar}</div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  )
}

MedicalReportModal.defaultProps = {
  prescriptionModalData: {},
}

MedicalReportModal.propTypes = {
  prescriptionModalData: PropTypes.object.isRequired,
  appearanceSetting: PropTypes.object,
  ViewAppearance: PropTypes.func.isRequired,
  headerHieght: PropTypes.number,
  footerHieght: PropTypes.number,
}

const mapStateToProps = state => ({
  appearanceSetting: state.Appearance.singleRow,
  headerHieght: state.Appearance.headerHieght,
  footerHieght: state.Appearance.footerHieght,
})

export default connect(mapStateToProps, { ViewAppearance })(MedicalReportModal)
