import { GET_ALL_INVESTIGATIONS, GET_INVESTIGATION_ID } from './actionTypes'

const initialState = {
  data: undefined,
  singleRow: {},
  last_page: null,
  total: null,
  per_page: 10,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INVESTIGATIONS:
      return {
        ...state,
        data: action.payload.data,
        last_page: action.payload.meta.last_page,
        total: action.payload.meta.total,
        per_page: action.payload.meta.per_page,
      }

    case GET_INVESTIGATION_ID:
      return {
        ...state,
        singleRow: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}
