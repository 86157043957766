import moment from 'moment'

export const searchFormatter = search => {
  let q = {}
  search
    ?.split('?')[1]
    ?.split('&')
    ?.map(item => {
      let pairs = item.split('=')
      if (pairs.length === 2) {
        q = { ...q, [pairs[0]]: pairs[1] }
      }
    })
  return q
}

export const clculateAgeBirthDate = (t, birthDate) => {
  const birthyear = moment(birthDate)
  const visitdate = moment()
  const ageMonthsDiff = visitdate.format('M') - birthyear.format('M')
  const ageYears = visitdate.diff(birthyear, 'y')
  const ageMonths = ageMonthsDiff < 0 ? 12 - -1 * ageMonthsDiff : ageMonthsDiff
  const ageDays = visitdate.format('D') - birthyear.format('D')

  let age = ''
  if (birthDate) {
    if (ageYears > 0) {
      age = ageYears + t('years') + (ageMonths > 0 ? '-' + ageMonths + t('months') : '')
    } else if (ageYears === 0) {
      age =
        (ageMonths > 0 ? ageMonths + t('months') : '') +
        (ageDays > 0 && ageMonths > 0
          ? '-' + ageDays + t('dayss')
          : ageDays > 0 && ageMonths <= 0
          ? ageDays + t('dayss')
          : '')
    }
  }
  return age
}

export const convertToObj = obj => {
  const result = {}

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.')

    // Create sub-objects along path as needed
    let target = result
    while (parts.length > 1) {
      const part = parts.shift()
      target = target[part] = target[part] || {}
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath]
  }

  return result
}
