import { GET_ALL_MESSAGES, GET_MESSAGE_ID, GET_CREDIT } from './actionTypes'
import moment from 'moment'
const initialState = {
  data: undefined,
  singleRow: {},
  last_page: null,
  total: null,
  per_page: 10,
  messagesBalance: null,
}

export default (state = initialState, action) => {
  let tableData = action.payload?.data?.map(obj => {
    return {
      ...obj,
      code: obj.patient?.id,
      name: obj.patient?.name,
      date: moment(obj?.DelayUntil).format('DD/MM/YYYY'),
      time: moment(obj?.DelayUntil).format('HH:mm'),
    }
  })
  switch (action.type) {
    case GET_ALL_MESSAGES:
      return {
        ...state,
        data: tableData,
        last_page: action.payload.meta.last_page,
        total: action.payload.meta.total,
        per_page: action.payload.meta.per_page,
      }

    case GET_CREDIT:
      return {
        ...state,
        data: tableData,
        messagesBalance: action.payload?.current,
      }

    case GET_MESSAGE_ID:
      return {
        ...state,
        singleRow: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}
