import { GET_LOGS } from './actionTypes'
import Request from 'utils/Request'
import { redirectUnAuthApi } from 'store/actions'

let urlPath = '/log'

export const getAllLogs = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath +
            `?page=${pageNo}&user=${filterData.user_id || ''}&description=${
              filterData?.type ? filterData.type?.trim() : ''
            }&from=${filterData.from || ''}&to=${filterData.to || ''}`
        : urlPath + `?page=${pageNo}`,
    )
      .then(({ data }) => {
        return dispatch({
          type: GET_LOGS,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}
