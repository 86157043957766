import { useEffect } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ViewAppearance } from 'store/actions'
import { connect } from 'react-redux'
//history
import history from 'utils/history'
import { ConfigProvider } from 'antd'
//routes
import { privateRoutes, publicRoutes } from 'routes'
//
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'
//layouts
import AuthLayout from 'components/Layouts/AuthLayout'
import NonAuthLayout from 'components/Layouts/NonAuthLayout'
//middleware
import PrivateRouteMiddleware from 'routes/Middleware/PrivateRoute'
import PublicRouteMiddleware from 'routes/Middleware/PublicRoute'
//utils
import { changeDirictions } from 'utils/translation'

export const translate = value => {
  const { t } = useTranslation()
  console.log(t(value))
}

const App = ({ ViewAppearance }) => {
  translate()
  const {
    i18n: { language },
  } = useTranslation('', { i18n })

  /* call view api to return data */
  useEffect(() => {
    ViewAppearance(1)
  }, [])

  useEffect(() => {
    changeDirictions(language)
  }, [language])

  return (
    <div className="App">
      {/* <ConfigProvider> */}
      <ConfigProvider direction={language === 'ar' ? 'rtl' : 'ltr'}>
        <Router history={history}>
          <Switch>
            {privateRoutes.map((route, idx) => (
              <PrivateRouteMiddleware
                path={route.path}
                layout={AuthLayout}
                component={route.component}
                key={String(idx)}
              />
            ))}
            {publicRoutes.map((route, idx) => (
              <PublicRouteMiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={String(idx)}
              />
            ))}
            <Route path="*" render={() => <Redirect to={{ pathname: '/login' }} />} />
          </Switch>
        </Router>
      </ConfigProvider>
    </div>
  )
}

App.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
  ViewAppearance: PropTypes.func.isRequired,
}
export default connect(null, { ViewAppearance })(App)
