import { GET_ALL_DASHBOARD } from './actionTypes'

const initialState = {
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DASHBOARD:
      return {
        ...state,
        data: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}
