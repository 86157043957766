import { lazy } from 'react'
//auth
const Login = lazy(() => import('pages/LogIn'))

//Dashboard
const Dashboard = lazy(() => import('pages/Dashboard'))

//Statistics
const Statistics = lazy(() => import('pages/Statistics'))

//Patients
const CreatePatient = lazy(() => import('pages/Patients/AddPatient/Create'))
const UpdatePatient = lazy(() => import('pages/Patients/AddPatient/Update'))
const ViewPatient = lazy(() => import('pages/Patients/AddPatient/View'))

const CreateMultiPatient = lazy(() => import('pages/Patients/AddMultiplePatient/Create'))
const Patients = lazy(() => import('pages/Patients'))

//Pation histoty
// const CreatePrescription = lazy(() => import('pages/PatientHistory/Prescriptions/Create'))
// const UpdatePrescription = lazy(() => import('pages/PatientHistory/Prescriptions/Update'))
// const ViewPrescription = lazy(() => import('pages/PatientHistory/Prescriptions/View'))
// const Prescriptions = lazy(() => import('pages/PatientHistory/Prescriptions'))
const ViewTransactionHistory = lazy(() => import('pages/PatientHistory/TransactionHistory/View'))
const TransactionsHistory = lazy(() => import('pages/PatientHistory/TransactionHistory'))

//Bookings
const Appointments = lazy(() => import('pages/Booking/Appointments'))
const CreateAppointment = lazy(() => import('pages/Booking/Appointments/Create/index'))
const UpdateAppointment = lazy(() => import('pages/Booking/Appointments/Update'))
const ViewAppointment = lazy(() => import('pages/Booking/Appointments/View'))

// const ProceduresBoking = lazy(() => import('pages/Booking/Procedures'))
// const CreateProcedure = lazy(() => import('pages/Booking/Procedures/Create/index'))
// const UpdateProcedure = lazy(() => import('pages/Booking/Procedures/Update'))
// const ViewProcedure = lazy(() => import('pages/Booking/Procedures/View'))

//Appearance
const Appearance = lazy(() => import('pages/Appearance'))

// Messages
const AddMessage = lazy(() => import('pages/Messages/AddMessage/Create'))
const AddMessageGroup = lazy(() => import('pages/Messages/AddMessageGroup/Create'))
const Messages = lazy(() => import('pages/Messages'))

//Configurations
const Branches = lazy(() => import('pages/Configurations/Branches'))
const Diagnosis = lazy(() => import('pages/Configurations/Diagnosis'))
const Drugs = lazy(() => import('pages/Configurations/Drugs'))
// const Examinations = lazy(() => import('pages/Configurations/Examinations'))
const Investigations = lazy(() => import('pages/Configurations/Investigations'))
// const Hospitals = lazy(() => import('pages/Configurations/Hospitals'))
const Procedures = lazy(() => import('pages/Configurations/Procedures'))
const Insurances = lazy(() => import('pages/Configurations/Insurances'))
const MessageForms = lazy(() => import('pages/Configurations/MessageForms'))

//Users
const CreateUser = lazy(() => import('pages/Users/Create'))
const UpdateUser = lazy(() => import('pages/Users/Update'))
const ViewUser = lazy(() => import('pages/Users/View'))
const Users = lazy(() => import('pages/Users'))

//Logs
const Logs = lazy(() => import('pages/Logs'))

/* ***** Login  ****** */
export const publicRoutes = [{ path: '/login', component: Login }]

/* ***** Pages  ****** */
export const privateRoutes = [
  //Dashboard
  { path: '/dashboard', component: Dashboard },

  //Statistics
  { path: '/statistics', component: Statistics },
  //patients
  { path: '/patients/add-patient', component: CreatePatient },
  { path: '/patients/update-patient/:id', component: UpdatePatient },
  { path: '/patients/view-patient/:id', component: ViewPatient },
  { path: '/patients/add-multiple-patients', component: CreateMultiPatient },
  { path: '/patients', component: Patients },

  //Booking

  { path: '/appointments/add-appointment', component: CreateAppointment },
  { path: '/appointments/update-appointment/:id', component: UpdateAppointment },
  { path: '/appointments/view-appointment/:id', component: ViewAppointment },
  { path: '/appointments', component: Appointments },

  // { path: '/bookings/procedures/add-procedure', component: CreateProcedure },
  // { path: '/bookings/procedures/update-procedure/:id', component: UpdateProcedure },
  // { path: '/bookings/procedures/view-procedure/:id', component: ViewProcedure },
  // { path: '/bookings/procedures', component: ProceduresBoking },

  //Appearance
  { path: '/appearance', component: Appearance },

  //Messages
  { path: '/sms/messages/add-message', component: AddMessage },
  { path: '/sms/messages/add-message-group', component: AddMessageGroup },
  { path: '/sms/messages', component: Messages },

  //Configurations
  { path: '/configurations/branches', component: Branches },
  { path: '/configurations/diagnosis', component: Diagnosis },
  { path: '/configurations/drugs', component: Drugs },
  // { path: '/configurations/examinations', component: Examinations },
  { path: '/configurations/investigations', component: Investigations },
  // { path: '/configurations/hospitals', component: Hospitals },
  { path: '/configurations/appointments', component: Procedures },
  { path: '/configurations/insurance_companies', component: Insurances },
  { path: '/sms/message-forms', component: MessageForms },

  //Users
  { path: '/users/add-user', component: CreateUser },
  { path: '/users/update-user/:id', component: UpdateUser },
  { path: '/users/view-user/:id', component: ViewUser },
  { path: '/users', component: Users },

  //Logs
  { path: '/logs', component: Logs },

  //Patient history
  // { path: '/patient-history/prescriptions/add-prescription', component: CreatePrescription },
  // { path: '/patient-history/prescriptions/update-prescription/:id', component: UpdatePrescription },
  // { path: '/patient-history/prescriptions/view-prescription/:id', component: ViewPrescription },
  // { path: '/patient-history/prescriptions', component: Prescriptions },
  {
    path: '/transactions-history/view-transactions-history/:id',
    component: ViewTransactionHistory,
  },
  { path: '/transactions-history', component: TransactionsHistory },
]
