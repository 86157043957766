import axios from 'axios'
import jsCookie from 'js-cookie'
import { AUTH_TOKEN_NAME } from './constants'
import { APIS_BASE_URL } from './constants'

// Set config defaults when creating the instance
const instance = axios.create({
  //window.location.origin +
  baseURL: APIS_BASE_URL,
})

let token = jsCookie.get(AUTH_TOKEN_NAME)
let user = jsCookie.get('name')
let email = jsCookie.get('email')
// Alter defaults after instance has been created
instance.defaults.headers.common['user'] = user
instance.defaults.headers.common['email'] = email
instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
instance.defaults.headers.common['Accept'] = 'application/json'
instance.defaults.headers.post['Content-Type'] = 'application/json'

export default instance
