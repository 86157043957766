import React from 'react'
import History from 'utils/history'
import { notification } from 'antd'
import { LOADING, OPEN_NEW_FORM, OPEN_EDIT_FORM, OPEN_VIEW_FORM, CLOSE_FORM } from './actionTypes'

export const openAddForm = () => {
  return {
    type: OPEN_NEW_FORM,
    payload: {
      openNew: true,
      openEdit: false,
      openView: false,
    },
  }
}

export const openEditForm = () => {
  return {
    type: OPEN_EDIT_FORM,
    payload: {
      openNew: false,
      openEdit: true,
      openView: false,
    },
  }
}

export const openViewForm = () => {
  return {
    type: OPEN_VIEW_FORM,
    payload: {
      openNew: false,
      openEdit: false,
      openView: true,
    },
  }
}

export const closeForm = () => {
  return {
    type: CLOSE_FORM,
    payload: {
      openNew: false,
      openEdit: false,
      openView: false,
    },
  }
}

//gloabal loader
export const Loading = (loaderName, status) => dispatch => {
  dispatch({ type: LOADING, payload: { [loaderName]: status } })
}

export function HandleSuccessRes(title, content) {
  notification['success']({
    message: title,
    description: content,
  })
}
export function HandleErrorRes(title, content) {
  notification['error']({
    message: title,
    description: content,
  })
}

export const HandleValidationsError = async (t, errors) => {
  const ArrErrors = []
  if (typeof errors === 'object' && errors !== null && errors !== undefined) {
    for (const [key, value] of Object.entries(errors)) {
      await ArrErrors?.push(`${key}: ${value}`)
    }

    const contErrors = await ArrErrors?.map((msg, idx) => (
      <div key={idx}>
        {idx + 1} - {msg} <hr />
      </div>
    ))
    HandleErrorRes(t('failed'), contErrors)
  }
}

export const HandleCachError = (error, callback) => {
  return dispatch => {
    dispatch({ type: LOADING, payload: false })
    if (!error.response) {
      // in case internal server error 500
      if (navigator.onLine) {
        notification['error']({
          message: 'Server Error',
          description: 'something went wrong with your request.please try again later',
        })
        // dispatch(HandleErrorRes())
      } else {
        notification['error']({
          message: 'NetWork Error',
          description: 'please check your connection',
        })
        // dispatch(HandleErrorRes('please check your connection'))
      }
    } else if (error?.response?.status === 422) {
      if (callback) {
        dispatch(callback(error.response.data))
      }
    } else if (error?.response?.status === 401) {
      // in case unauthorized  401
      // localStorage.removeItem("user")
      // dispatch({ type: LOGIN, payload: { user: null, Login: false } })
      History.push('/')
    } else if (error.response.data && error.response.data.length) {
      //custom response error
      if (callback) {
        dispatch(callback(error.response.data))
      }
    } else if (navigator.onLine) {
      // in case internal server error 500
      notification['error']({
        message: 'Server Error',
        description: 'something went wrong with your request.please try again later',
      })
      // dispatch(HandleErrorRes())
    } else {
      notification['error']({
        message: 'NetWork Error',
        description: 'please check your connection',
      })
      // dispatch(HandleErrorRes('please check your connection'))
    }
  }
}
