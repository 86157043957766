/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setModalInvoiceData, ViewAppearance } from 'store/actions'
import { SEED_REDUCER_DATA } from 'store/Layout/actionTypes'
import { BASE_URL } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import { connect } from 'react-redux'
import moment from 'moment'
import defaultlogo from 'assets/images/logo-icon.svg'
import { clculateAgeBirthDate } from 'utils/helpers'

const { Title, Text } = Typography

const MedicalReportModal = ({
  medicalReportModalData,
  appearanceSetting,
  ViewAppearance,
  headerHieght,
  footerHieght,
}) => {
  const reportData = {
    ...medicalReportModalData?.personal_information,
    bookings: medicalReportModalData?.bookings,
    weight: medicalReportModalData?.weight,
  }

  const {
    t,
    i18n: { language },
  } = useTranslation('', { i18n })
  const { medicalReportModalStatus } = useSelector(state => state.Layout)
  const dispatch = useDispatch()

  const logo = appearanceSetting?.media?.logo
    ? `${BASE_URL}${appearanceSetting.media.logo?.url}`
    : defaultlogo

  /* call Appearance view api to return data */
  useEffect(() => {
    ViewAppearance(1)
    return () => {}
  }, [])

  const handleCancel = () => {
    dispatch(
      setModalInvoiceData({
        type: SEED_REDUCER_DATA,
        medicalReportModalStatus: false,
      }),
    )
  }

  window.onafterprint = function () {
    handleCancel()
  }

  useEffect(async () => {
    if (medicalReportModalStatus) {
      await ViewAppearance(1)
      await window.print()
    }
    return () => {
      handleCancel()
    }
  }, [medicalReportModalStatus])

  return (
    <div id="section-to-print" className="report__modal px-2 py-3">
      {appearanceSetting?.custom_printing === false && (
        <div className="fixed-top border-bottom">
          <Row className="" gutter={8} style={{ direction: 'ltr' }}>
            <Col xs={8} style={{ direction: 'ltr', textAlign: 'left' }}>
              <div>
                <Title level={5} className="my-0 text-primary">
                  {appearanceSetting?.name_en}
                </Title>
                <span className="my-0 text-muted font-size-13">{appearanceSetting?.scpeciality_en}</span>
              </div>
            </Col>
            <Col xs={8} className="text-center">
              <Title level={3} className="text-secondary">
                {t('medical report')}
              </Title>
              <div className="doctor-logo-wrapper">
                <div className="name-wrapper text-center">
                  <img src={logo} />
                </div>
              </div>
            </Col>
            <Col xs={8} style={{ direction: 'rtl', textAlign: 'right' }}>
              <div>
                <Title level={5} className="my-0 text-primary">
                  {appearanceSetting?.name_ar}
                </Title>
                <span className="my-0 text-muted font-size-13">{appearanceSetting?.scpeciality_ar}</span>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <table className="w-100">
        <thead style={{ backgroundColor: '#fff' }}>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position header--> */}
              <div
                className="page-header-space"
                style={{
                  height: appearanceSetting?.custom_printing ? headerHieght : 120,
                }}></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                {/* Med data */}
                <Row>
                  <Col xs={24}>
                    <Row gutter={8}>
                      <Col xs={24} className="border-card">
                        <div className="site-card-border-less-wrapper ">
                          <div className="d-flex align-items-center mb-1">
                            <Title level={5} className="mb-0 me-2">
                              {t('patient name')}
                            </Title>
                            <Text className="px-2">
                              <b>{reportData?.name}</b>
                            </Text>
                          </div>
                          <div className="d-flex align-items-center mb-1">
                            <Title level={5} className="mb-0 me-2">
                              {t('age')}
                            </Title>
                            <Text className="px-2">
                              <b>{String(clculateAgeBirthDate(t, reportData?.birthdate))}</b>
                            </Text>
                          </div>
                          <div className="d-flex align-items-center mb-1">
                            <Title level={5} className="mb-0 me-2">
                              {t('date')}
                            </Title>
                            <Text className="px-2">
                              <b>{moment(reportData?.date).format('DD/MM/YYYY')}</b>
                            </Text>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="my-3" gutter={8}>
                      <Col xs={24}>
                        {/* Med data */}
                        <Title level={4} className="my-2">
                          {t('medical data')}
                        </Title>
                      </Col>
                      <Col className="my-2 border-bottom" xs={24}>
                        <div className="site-card-border-less-wrapper">
                          <div className=" mb-2">
                            <Title level={5} className="mb-0 me-2">
                              {t('initial complaint')} :
                            </Title>
                            <p className="px-3">{reportData?.complaint || ''}</p>
                          </div>
                        </div>
                      </Col>
                      <Col className="my-2 border-bottom" xs={24}>
                        <div className="site-card-border-less-wrapper ">
                          <div className=" mb-2">
                            <Title level={5} className="mb-0 me-2">
                              {t('past disease history')} :
                            </Title>
                            <Text className="px-3">{reportData?.history || ''}</Text>
                          </div>
                        </div>
                      </Col>
                      <Col className="my-2 border-bottom" xs={24}>
                        <div className="site-card-border-less-wrapper ">
                          <div className=" mb-2">
                            <Title level={5} className="mb-0 me-2">
                              {t('family disease history')} :
                            </Title>
                            <Text className="px-3">{reportData?.family_history || ''}</Text>
                          </div>
                        </div>
                      </Col>
                      <Col className="my-2 border-bottom" xs={24}>
                        <div className="site-card-border-less-wrapper ">
                          <div className=" mb-2">
                            <Title level={5} className="mb-0 me-2">
                              {t('chronic diseases')} :
                            </Title>
                            <Text className="px-3">{reportData?.diseases || ''}</Text>
                          </div>
                        </div>
                      </Col>
                      <Col className="my-2 border-bottom" xs={24}>
                        <div className="site-card-border-less-wrapper ">
                          <div className=" mb-2">
                            <Title level={5} className="mb-0 me-2">
                              {t('previous operations')} :
                            </Title>
                            <Text className="px-3">{reportData?.operations || ''}</Text>
                          </div>
                        </div>
                      </Col>
                      <Col className="my-2" xs={24}>
                        <div className="site-card-border-less-wrapper ">
                          <div className=" mb-2">
                            <Title level={5} className="mb-0 me-2">
                              {t('allergy')} :
                            </Title>
                            <Text className="px-3">{reportData?.allergy || ''}</Text>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/*  weights of patient rate */}
                    {reportData?.weight?.length > 0 && (
                      <>
                        <Row className="my-3" gutter={8}>
                          <Col xs={24}>
                            <div className=" mb-2">
                              <Title level={4}>{t('weights of patient rate')}</Title>
                            </div>

                            <div className="mb-2 crm-datatable report-table">
                              <table className="table ">
                                <thead className="thead-light py-5">
                                  <tr>
                                    <th> {t('date')}</th>
                                    <th> {t('weight')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reportData.weight.map((obj, id) => (
                                    <tr key={id}>
                                      <td>{moment(obj?.created_at).format('DD/MM/YYYY') || ''}</td>
                                      <td>{obj?.weight || ''}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    {/*  Treatment */}
                    {reportData?.bookings?.length > 0 &&
                      reportData.bookings.map(
                        (presc, id) =>
                          presc?.drugs?.length > 0 && (
                            <Row className="my-3" gutter={8} key={id}>
                              <Col xs={24}>
                                <div className="d-flex align-items-center mb-2">
                                  <Title level={4}>{t('treatment')}</Title>
                                  <Text className="px-3">{moment(presc.date).format('DD/MM/YYYY')}</Text>
                                </div>

                                <div className="mb-2 crm-datatable report-table">
                                  {/* <Table columns={columns} dataSource={presc.drugs} pagination={false} /> */}
                                  <table className="table">
                                    <thead className="thead-light py-5">
                                      <tr>
                                        <th> {t('drug')}</th>
                                        <th> {t('dose')}</th>
                                        <th> {t('route of administration')}</th>
                                        <th> {t('period')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {presc?.drugs?.map(record => (
                                        <tr key={record?.drug_id}>
                                          <td>{record?.title || ''}</td>
                                          <td>{record?.dose || ''}</td>
                                          <td>{record?.route || ''}</td>
                                          <td>{record?.duration || ''}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          ),
                      )}
                  </Col>
                </Row>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position footer--> */}
              <div
                className="page-footer-space "
                style={{
                  height: appearanceSetting?.custom_printing ? footerHieght : 130,
                }}></div>
            </td>
          </tr>
        </tfoot>
      </table>
      {appearanceSetting?.custom_printing === false && (
        <div className="modal-footer fixed-bottom border-top" style={{ backgroundColor: '#fff' }}>
          <Row style={{ direction: 'ltr', width: '100%' }}>
            <Col xs={12} className="text-left">
              <div style={{ direction: 'ltr' }}>{appearanceSetting?.footer_en}</div>
            </Col>
            <Col xs={12} className="text-right">
              <div style={{ direction: 'rtl' }}>{appearanceSetting?.footer_ar}</div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

MedicalReportModal.defaultProps = {
  medicalReportModalData: {},
}

MedicalReportModal.propTypes = {
  medicalReportModalData: PropTypes.object.isRequired,
  appearanceSetting: PropTypes.object,
  ViewAppearance: PropTypes.func.isRequired,
  headerHieght: PropTypes.number,
  footerHieght: PropTypes.number,
}

const mapStateToProps = state => ({
  appearanceSetting: state.Appearance.singleRow,
  headerHieght: state.Appearance.headerHieght,
  footerHieght: state.Appearance.footerHieght,
})

export default connect(mapStateToProps, { ViewAppearance })(MedicalReportModal)
