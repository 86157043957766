/* eslint-disable no-unused-vars */
import { GET_ALL_INSURANCES, GET_INSURANCE_ID } from './actionTypes'
import Request from 'utils/Request'
import {
  HandleSuccessRes,
  HandleErrorRes,
  closeForm,
  redirectUnAuthApi,
  HandleValidationsError,
} from 'store/actions'

let urlPath = '/insurances'

export const getAllInsurances = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? `${urlPath}` +
            `?page=${pageNo}&country=${filterData?.country_id ? filterData.country_id : ''}&title=${
              filterData?.insurance ? filterData.insurance?.trim() : ''
            }&id=${filterData?.insurance_id || ''}`
        : `${urlPath}` + `?page=${pageNo}`,
    )
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_INSURANCES,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const createInsurance = (t, payload) => {
  return dispatch => {
    return Request.post(urlPath, { ...payload })
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        dispatch(closeForm())
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const ViewInsurance = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_INSURANCE_ID,
        payload: data.data,
      })
    })
  }
}

export const editInsurance = (t, id, payload) => {
  return dispatch => {
    return Request.put(urlPath + `/${id}`, { ...payload })
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully updated'))
        dispatch(closeForm())
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const deleteInsurance = (t, id) => {
  return dispatch => {
    return Request.delete(urlPath + `/${id}`)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully deleted'))
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        HandleErrorRes(t('failed'), t('delete failed'))
        return {
          type: '',
          payload: '',
        }
      })
  }
}
