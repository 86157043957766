/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import jsCookies from 'js-cookie'
import { Skeleton } from 'antd'
import { AUTH_TOKEN_NAME } from 'utils/constants'
import { isUserAuthenticated, redirectUnAuthApi } from 'store/actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import Loader from 'components/Layouts/Loader'

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  path,
  redirectUnAuthApi: redirectUnAuthApiAction,
}) => (
  <Route
    path={path}
    render={props => {
      // here you can apply condition
      if (!isUserAuthenticated()) {
        //remove
        jsCookies.remove(AUTH_TOKEN_NAME)
        //redirect
        return <Redirect to={{ pathname: '/login' }} />
        // return redirectUnAuthApiAction()
      }
      return (
        <Layout>
          <Suspense fallback={<Skeleton active />}>
            <Component {...props} />
          </Suspense>
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  component: PropTypes.oneOfType([PropTypes.any]).isRequired,
  layout: PropTypes.oneOfType([PropTypes.any]).isRequired,
  path: PropTypes.string.isRequired,
  redirectUnAuthApi: PropTypes.func.isRequired,
}

export default withRouter(connect(null, { isUserAuthenticated, redirectUnAuthApi })(Authmiddleware))
