/* eslint-disable no-unused-vars */
import { AUTH_TOKEN_NAME } from 'utils/constants'
import jsCookie from 'js-cookie'
import history from 'utils/history'
import { LOGIN_AUTH } from './actionTypes'
import { HandleSuccessRes, HandleErrorRes } from 'store/actions'
import Request from 'utils/Request'

export const userLogin = (t, payload) => {
  return dispatch => {
    return Request.post('/auth/login', payload)
      .then(({ data }) => {
        jsCookie.set(AUTH_TOKEN_NAME, data.data.auth_token.access_token, 1)
        jsCookie.set('role_id', data.data.roles[0].id, 1)
        jsCookie.set('email', data.data.user.email, 1)
        jsCookie.set('name', data.data.user.name, 1)

        const permissionsData = data.data?.roles[0]?.permissions?.map(obj => obj?.name)

        jsCookie.set('permissions', permissionsData, 1)

        HandleSuccessRes(t('success'), t('logged in successfully'))
        //update request instace token
        Request.defaults.headers.common['Authorization'] = `Bearer ${data.data.auth_token.access_token}`
        Request.defaults.headers.common['user'] = data.data.user.name
        Request.defaults.headers.common['email'] = data.data.user.email

        history.push('/dashboard')

        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        console.log('error', error)
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
          HandleErrorRes(t('failed'), t('invalid email or password'))
        } else if (Number(error?.response?.status) == 422) {
          HandleErrorRes(t('failed'), t(error?.response?.data?.message?.message))
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }

        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const userLogOut = () => {
  Request.post('/auth/logout', {})
  redirectUnAuthApi()
}

export const redirectUnAuthApi = () => {
  jsCookie.remove(AUTH_TOKEN_NAME)
  jsCookie.remove('email')
  jsCookie.remove('name')
  jsCookie.remove('role_id')
  jsCookie.remove('permissions')
  history.push('/login')
}

/**
 * check auth user token
 * @returns Booleans
 */
export const isUserAuthenticated = () => {
  //check jsCookie
  const token = jsCookie.get(AUTH_TOKEN_NAME)

  if (token) return token
  return null
}
