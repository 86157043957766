import React, { Suspense } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
// import Loader from 'components/Layouts/Loader'
import { connect } from 'react-redux'
import { isUserAuthenticated } from 'store/actions'

const NonAuthmiddleware = ({ component: Component, layout: Layout, path, history }) => (
  <Route
    path={path}
    exact
    render={props => {
      if (isUserAuthenticated()) {
        return <Redirect to={{ pathname: '/dashboard' }} />
      }

      return (
        <Layout>
          <Suspense fallback={<Skeleton active />}>
            <Component {...props} />
          </Suspense>
        </Layout>
      )
    }}
  />
)

NonAuthmiddleware.propTypes = {
  component: PropTypes.oneOfType([PropTypes.any]).isRequired,
  layout: PropTypes.oneOfType([PropTypes.any]).isRequired,
  path: PropTypes.string.isRequired,
}
export default withRouter(connect(null, { isUserAuthenticated })(NonAuthmiddleware))
