import React from 'react'
// import PropTypes from 'prop-types'
import { Popover, Button, Typography } from 'antd'
import { GrLanguage } from 'react-icons/gr'
import { FiChevronDown } from 'react-icons/fi'
// the hook
import { useTranslation } from 'react-i18next'

const LanguageMenu = () => {
  const { t, i18n } = useTranslation()

  const content = (
    <div>
      <Button type="link" onClick={() => i18n.changeLanguage('en')}>
        {t('en')}
      </Button>
      <br />
      <Button type="link" onClick={() => i18n.changeLanguage('ar')}>
        {t('ar')}
      </Button>
    </div>
  )

  return (
    <Popover placement="bottom" content={content} trigger="click">
      <Typography.Title level={5} className="m-0">
        <GrLanguage className="mb-1" />
        <Button type="text">{t(i18n.language)}</Button>
        <FiChevronDown className="mb-1" />
      </Typography.Title>
    </Popover>
  )
}

export default LanguageMenu
