import { GET_ALL_STATISTICS } from './actionTypes'
import moment from 'moment'
import { redirectUnAuthApi } from 'store/actions'
import Request from 'utils/Request'

export const getAllStatistics = filterData => {
  return dispatch => {
    return Request.get(
      `/statistics?from=${moment(filterData.from).format('YYYY-MM-DD')}&to=${moment(filterData.to).format(
        'YYYY-MM-DD',
      )}`,
    )
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_STATISTICS,
          payload: {
            Bookings: data.bookings,
            PatientsRateData: data.patients,
            BranchesPerfData: data.branche_bookings,
            MostProcedure: data.common_procedures,
            MostDayTotal: data.active_days,
          },
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}
