import { GET_ALL_DASHBOARD } from './actionTypes'
import moment from 'moment'
import { redirectUnAuthApi } from 'store/actions'
import Request from 'utils/Request'

export const getAllDashboard = filterData => {
  return dispatch => {
    return Request.get(
      `/dashboard?from=${moment(filterData.from).format('YYYY-MM-DD')}&to=${moment(filterData.to).format(
        'YYYY-MM-DD',
      )}`,
    )
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_DASHBOARD,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}
