import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

function NonAuthLayout({ children }) {
  const location = useLocation()

  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  useEffect(() => {
    const currentage = capitalizeFirstLetter(location.pathname)
    document.title = `${currentage} | CRM`
    return () => {}
  }, [])
  return <div>{children}</div>
}

NonAuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NonAuthLayout
