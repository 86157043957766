import { LOADING, OPEN_NEW_FORM, OPEN_EDIT_FORM, CLOSE_FORM, OPEN_VIEW_FORM } from './actionTypes'

const initialState = {
  loader: {},
  openNew: false,
  openEdit: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      state = {
        ...state,
        loader: { ...payload },
      }
      break
    case OPEN_NEW_FORM:
      return {
        ...state,
        ...payload,
      }
    case OPEN_EDIT_FORM:
      return {
        ...state,
        ...payload,
      }
    case OPEN_VIEW_FORM:
      return {
        ...state,
        ...payload,
      }

    case CLOSE_FORM:
      return {
        ...state,
        ...payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}
