/* eslint-disable no-unused-vars */
import { GET_ALL_MESSAGES, GET_MESSAGE_ID, GET_CREDIT } from './actionTypes'
import Request from 'utils/Request'
import History from 'utils/history'
import {
  HandleSuccessRes,
  HandleErrorRes,
  redirectUnAuthApi,
  HandleValidationsError,
  closeForm,
} from 'store/actions'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import moment from 'moment'

let urlPath = '/sms'

export const getAllMessages = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath +
            `?page=${pageNo}&patient_id=${filterData?.patient_code || filterData?.patient_id || ''}&date=${
              filterData?.date ? moment(filterData.date).format('YYYY-MM-DD') : ''
            }&time=${filterData?.time ? moment(filterData.time).format('YYYY-MM-DD HH:mm') : ''}`
        : urlPath + `?page=${pageNo}`,
    )
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_MESSAGES,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const getCredit = () => {
  return dispatch => {
    return Request.get(`${urlPath}/credit`)
      .then(({ data }) => {
        return dispatch({
          type: GET_CREDIT,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const createMessage = (t, payload) => {
  return dispatch => {
    return Request.post(urlPath, payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        History.push('/sms/messages')
        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const createMessageBalance = (t, payload) => {
  return dispatch => {
    return Request.post(urlPath, payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        History.push('/sms/messages')
        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const ViewMessage = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_MESSAGE_ID,
        payload: data.data,
      })
    })
  }
}

export const editMessage = (t, id, payload) => {
  return dispatch => {
    return Request.put(urlPath + `/${id}`, payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully updated'))
        History.push('/sms/messages')
        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const deleteMessage = (t, id,pageNo, filterData) => {
  return dispatch => {
    return Request.delete(urlPath + `/${id}`)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully deleted'))
        dispatch(getAllMessages(pageNo, filterData))
        History.push('/sms/messages')
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        HandleErrorRes(t('failed'), t('delete failed'))
        return {
          type: '',
          payload: '',
        }
      })
  }
}

/* Handle Delete Data Row */
export const handleDeleteMessageRow = (id, t,pageNo, filterData) => {
  return dispatch => {
    Modal.confirm({
      title: t('are you sure delete this'),
      icon: <ExclamationCircleOutlined />,
      content: t('you wont be able to revert this'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        dispatch(deleteMessage(t, id,pageNo, filterData))
      },
    })
  }
}

/* send message for tech support */

export const sendMessageTechSupp = (t, payload) => {
  return dispatch => {
    return Request.post('/cs', payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully sent'))
        dispatch(closeForm())
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('send failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}
