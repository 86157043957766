import { combineReducers } from 'redux'

/* Auth */
import Auth from './Auth/reducer'

/* Layout */
import Layout from './Layout/reducer'
import Global from './Global/reducer'

/* Dashboard */
import Dashboard from './Pages/Dashboard/reducer'

/* Statistics */
import Statistics from './Pages/Statistics/reducer'

/* Patients */
import Patients from './Pages/Patients/reducer'
/* history */
import TransactionHistory from './Pages/PatientHistory/TransactionHistory/reducer'
import Prescriptions from './Pages/PatientHistory/Prescriptions/reducer'

/* Bookings */
import Appointments from './Pages/Bookings/Appointments/reducer'
// import Procedure from './Pages/Bookings/Procedures/reducer'

/* Apperance */
import Appearance from './Pages/Appearance/reducer'

/* Messages */
import Messages from './Pages/Messages/reducer'

/* Configurations */
import Branches from './Pages/Configurations/Branches/reducer'
import Diagnosis from './Pages/Configurations/Diagnosis/reducer'
import Drugs from './Pages/Configurations/Drugs/reducer'
import Examinations from './Pages/Configurations/Examinations/reducer'
import Hospitals from './Pages/Configurations/Hospitals/reducer'
import Procedures from './Pages/Configurations/Procedures/reducer'
import Investigations from './Pages/Configurations/Investigations/reducer'
import MessageForms from './Pages/Configurations/MessageForms/reducer'
import Settings from './Pages/Configurations/Settings/reducer'
import Insurances from './Pages/Configurations/Insurances/reducer'

/* Users */
import Users from './Pages/Users/reducer'

/* Users */
import Logs from './Pages/Logs/reducer'

const History = combineReducers({
  Prescriptions,
  Transactions: TransactionHistory,
})
const Bookings = combineReducers({
  Appointments,
  // Procedure,
})

const Configurations = combineReducers({
  Branches,
  Diagnosis,
  Drugs,
  Examinations,
  Hospitals,
  Procedures,
  Investigations,
  MessageForms,
  Settings,
  Insurances,
})

const rootReducer = combineReducers({
  Layout,
  Auth,
  Dashboard,
  Statistics,
  Patients,
  History,
  Bookings,
  Appearance,
  Configurations,
  Global,
  Users,
  Logs,
  Messages,
})

export default rootReducer
