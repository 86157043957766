/* eslint-disable no-unused-vars */
import { GET_ALL_APPOINTMENTS, GET_APPOINTMENT_ID } from './actionTypes'
import Request from 'utils/Request'
import History from 'utils/history'
import { HandleSuccessRes, HandleErrorRes, redirectUnAuthApi, HandleValidationsError } from 'store/actions'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import moment from 'moment'

let urlPath = '/bookings'

export const getAllAppointments = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath +
            `?page=${pageNo}&type=${filterData.type || ''}&patient=${filterData.patientId || ''}&date=${
              filterData.appointment_date ? moment(filterData.appointment_date).format('YYYY-MM-DD') : ''
            }&time=${filterData.appointment_time ? moment(filterData.appointment_time).format('HH:mm') : ''}`
        : urlPath + `?page=${pageNo}`,
    )
      .then(({ data }) => {
        data.data = data.data.map(item => ({
          ...item,
          name: item.patient?.name,
        }))
        return dispatch({
          type: GET_ALL_APPOINTMENTS,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const createAppointment = (t, payload) => {
  return dispatch => {
    return Request.post(urlPath, payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        History.push('/appointments')
        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const ViewAppointment = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_APPOINTMENT_ID,
        payload: data.data,
      })
    })
  }
}

export const editAppointment = (t, id, payload) => {
  return dispatch => {
    return Request.post(urlPath + `/${id}`, payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully updated'))
        History.push('/appointments')
        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const deleteAppointment = (t, id, pageNo, filterData) => {
  return dispatch => {
    return Request.delete(urlPath + `/${id}`)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully deleted'))
        dispatch(getAllAppointments(pageNo, filterData))
        History.push('/appointments')
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        HandleErrorRes(t('failed'), t('delete failed'))
        return {
          type: '',
          payload: '',
        }
      })
  }
}

/* Handle Delete Data Row */
export const handleDeleteAppointmentRow = (id, t, pageNo, filterData) => {
  return dispatch => {
    Modal.confirm({
      title: t('are you sure delete this'),
      icon: <ExclamationCircleOutlined />,
      content: t('you wont be able to revert this'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        dispatch(deleteAppointment(t, id, pageNo, filterData))
      },
    })
  }
}
