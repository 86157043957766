/* eslint-disable no-unused-vars */
import { GET_ALL_BRANCHS, GET_BRANCH_ID } from './actionTypes'
import Request from 'utils/Request'
import {
  HandleSuccessRes,
  HandleErrorRes,
  closeForm,
  redirectUnAuthApi,
  HandleValidationsError,
} from 'store/actions'

let urlPath = '/settings'

export const getAllBranchs = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath +
            `?type=branche&page=${pageNo}&title=${filterData?.branch ? filterData.branch.trim() : ''}${
              filterData?.branch_ids?.length > 0
                ? filterData.branch_ids.map(val => '&ids[]=' + val).join('')
                : ''
            }`
        : urlPath + `?type=branche&page=${pageNo}`,
    )
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_BRANCHS,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const createBranch = (t, payload) => {
  return dispatch => {
    return Request.post(urlPath, { ...payload, type: 'branche' })
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        dispatch(closeForm())
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const ViewBranch = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_BRANCH_ID,
        payload: data.data,
      })
    })
  }
}

export const editBranch = (t, id, payload) => {
  return dispatch => {
    return Request.put(urlPath + `/${id}`, { ...payload, type: 'branche' })
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully updated'))
        dispatch(closeForm())
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const deleteBranch = (t, id) => {
  return dispatch => {
    return Request.delete(urlPath + `/${id}`)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully deleted'))
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        HandleErrorRes(t('failed'), t('delete failed'))
        return {
          type: '',
          payload: '',
        }
      })
  }
}
