import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Typography } from 'antd'
import { setModalInvoiceData, ViewAppearance } from 'store/actions'
import { SEED_REDUCER_DATA } from 'store/Layout/actionTypes'
import { connect } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import { BASE_URL } from 'utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'
import defaultlogo from 'assets/images/logo-icon.svg'

const { Title, Text } = Typography

const TtransactionsInvoice = ({
  invoiceModalData,
  appearanceSetting,
  ViewAppearance,
  headerHieght,
  footerHieght,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('', { i18n })
  const { invoiceModalStatus } = useSelector(state => state.Layout)
  const [dataSource, setdataSource] = useState([
    {
      id: 0,
      item: '',
      price: 0,
    },
  ])
  const dispatch = useDispatch()

  const logo = appearanceSetting?.media?.logo
    ? `${BASE_URL}${appearanceSetting.media.logo?.url}`
    : defaultlogo

  /* call Appearance view api to return data */
  useEffect(() => {
    ViewAppearance(1)
    return () => {}
  }, [])

  const handleCancel = () => {
    dispatch(
      setModalInvoiceData({
        type: SEED_REDUCER_DATA,
        invoiceModalStatus: false,
      }),
    )
  }

  window.onafterprint = function () {
    handleCancel()
  }

  useEffect(async () => {
    if (invoiceModalStatus) {
      await ViewAppearance(1)
      await setdataSource([{ id: 1, item: t(invoiceModalData.type), price: invoiceModalData?.total }])
      await window.print()
    }
    return () => {
      handleCancel()
    }
  }, [invoiceModalStatus])

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: t('item'),
      dataIndex: 'item',
    },
    {
      title: t('price'),
      dataIndex: 'price',
      render: price => `${price}`,
    },
  ]
  return (
    <div id="section-to-print" className="report__modal invoice">
      {appearanceSetting?.custom_printing === false ? (
        <Row className="mb-2 modal-header">
          <div className="date_id-card font-size-13">
            <div>
              <Text className="d-block">
                {t('invoice no')} : <b>{invoiceModalData?.id}</b>
              </Text>
              <Text className="d-block">
                {t('date')} : <b>{moment(invoiceModalData?.date).format('DD/MM/YYYY')}</b>
              </Text>
            </div>
          </div>
          <Col xs={24} className="d-flex justify-content-between">
            <div className="doctor-logo-wrapper justify-content-start">
              <div className="name-wrapper">
                <img src={logo} />
              </div>
            </div>
            <div className="invoice__right-title">
              <Title level={3} className="name my-0 text-white">
                {t('invoice')}
              </Title>
            </div>
          </Col>
          <div className="patient-name font-size-15">
            <Text className="me-1">{t('patient name')} </Text>
            <Text className="my-0 mx-2">{invoiceModalData?.patient?.name}</Text>
          </div>
        </Row>
      ) : (
        <div
          className="page-header-space"
          style={{
            height: headerHieght,
          }}>
          &nbsp;
        </div>
      )}
      <div className="modal-body font-size-14">
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        <div className="my-4 calculations">
          <div>
            <div className="d-flex align-items-center">
              <Text className="mx-3">{t('total')} </Text>
              <Text className="my-0">{invoiceModalData?.total}</Text>
            </div>
            <div className="d-flex align-items-center">
              <Text className="mx-3">{t('paid')} </Text>
              <Text className="my-0">{invoiceModalData?.total - invoiceModalData?.unpaid}</Text>
            </div>
            <div className="d-flex align-items-center">
              <Text className="mx-3">{t('change')} </Text>
              <Text className="my-0">{invoiceModalData?.unpaid}</Text>
            </div>
          </div>
        </div>
      </div>
      {appearanceSetting?.custom_printing === false ? (
        <div className="modal-footer fixed-bottom">
          <Row style={{ direction: 'ltr', width: '100%' }}>
            <Col xs={12} className="text-left">
              <div style={{ direction: 'ltr' }}>{appearanceSetting?.footer_en}</div>
            </Col>
            <Col xs={12} className="text-right">
              <div style={{ direction: 'rtl' }}>{appearanceSetting?.footer_ar}</div>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          className="page-footer-space "
          style={{
            height: footerHieght,
          }}></div>
      )}
    </div>
  )
}

TtransactionsInvoice.defaultProps = {
  invoiceModalData: {},
}

TtransactionsInvoice.propTypes = {
  invoiceModalData: PropTypes.object.isRequired,
  appearanceSetting: PropTypes.object,
  ViewAppearance: PropTypes.func.isRequired,
  headerHieght: PropTypes.number,
  footerHieght: PropTypes.number,
}

const mapStateToProps = state => ({
  appearanceSetting: state.Appearance.singleRow,
  headerHieght: state.Appearance.headerHieght,
  footerHieght: state.Appearance.footerHieght,
})

export default connect(mapStateToProps, { ViewAppearance })(TtransactionsInvoice)
