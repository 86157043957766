/* eslint-disable no-unused-vars */
import { GET_ALL_PROCEDURES, GET_PROCEDURE_ID } from './actionTypes'
import Request from 'utils/Request'
import {
  HandleSuccessRes,
  HandleErrorRes,
  closeForm,
  redirectUnAuthApi,
  HandleValidationsError,
} from 'store/actions'

let urlPath = '/settings'

export const getAllProcedures = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath +
            `?type=procedure&page=${pageNo}&title=${
              filterData?.procedure ? filterData.procedure?.trim() : ''
            }${
              filterData?.procedure_ids?.length > 0
                ? filterData.procedure_ids.map(val => '&ids[]=' + val).join('')
                : ''
            }`
        : urlPath + `?type=procedure&page=${pageNo}`,
    )
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_PROCEDURES,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const createProcedure = (t, payload) => {
  return dispatch => {
    return Request.post(urlPath, { ...payload, type: 'procedure' })
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        dispatch(closeForm())
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const ViewProcedure = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_PROCEDURE_ID,
        payload: data.data,
      })
    })
  }
}

export const editProcedure = (t, id, payload) => {
  return dispatch => {
    return Request.put(urlPath + `/${id}`, { ...payload, type: 'procedure' })
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully updated'))
        dispatch(closeForm())
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        if (error?.response?.data?.errors) {
          HandleValidationsError(t, error.response.data.errors)
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const deleteProcedure = (t, id) => {
  return dispatch => {
    return Request.delete(urlPath + `/${id}`)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully deleted'))
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        HandleErrorRes(t('failed'), t('delete failed'))
        return {
          type: '',
          payload: '',
        }
      })
  }
}
