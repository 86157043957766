import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  MenuUnfoldOutlined,
  BarChartOutlined,
  CalendarOutlined,
  UserAddOutlined,
  DashboardOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import jsCookie from 'js-cookie'

const { Header } = Layout

const TopHeader = ({ setVisible, openKeys, onOpenChange }) => {
  const permissions = jsCookie.get('permissions')

  return (
    <Header
      style={{
        position: 'fixed',
        zIndex: 999,
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <div>
        <button className="btn" onClick={() => setVisible(true)}>
          <MenuUnfoldOutlined style={{ fontSize: '23px' }} />
        </button>
      </div>
      <div className="menu-sm-header">
        <Menu
          theme="light"
          mode="horizontal"
          selectable={true}
          selectedKeys={[location.pathname]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultSelectedKeys={['/dashboard']}>
          {permissions?.includes('statistics') && (
            <Menu.Item key="/statistics">
              <Link to="/statistics">
                <BarChartOutlined style={{ fontSize: '23px' }} />
              </Link>
            </Menu.Item>
          )}
          {permissions?.includes('bookings.index') && (
            <Menu.Item key="/appointments">
              <Link to="/appointments">
                <CalendarOutlined style={{ fontSize: '23px' }} />
              </Link>
            </Menu.Item>
          )}
          {permissions?.includes('patients.index') && (
            <Menu.Item key="/patients">
              <Link to="/patients">
                <UserAddOutlined style={{ fontSize: '23px' }} />
              </Link>
            </Menu.Item>
          )}
          {permissions?.includes('dashboard.index') && (
            <Menu.Item key="/dashboard">
              <Link to="/dashboard">
                <DashboardOutlined style={{ fontSize: '23px' }} />
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </div>
    </Header>
  )
}

TopHeader.propTypes = {
  setVisible: PropTypes.node.isRequired,
  openKeys: PropTypes.string.isRequired,
  onOpenChange: PropTypes.func.isRequired,
}

export default TopHeader
