import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import {
  UserAddOutlined,
  DashboardOutlined,
  ControlOutlined,
  DollarOutlined,
  CalendarOutlined,
  SettingOutlined,
  BarChartOutlined,
  UserOutlined,
  MobileOutlined,
  MessageOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Dropdown, Card, Avatar, Button } from 'antd'
import defaultlogo from 'assets/images/logo-icon.svg'
import LanguageMenu from 'components/LanguageMenu'
import { userLogOut } from 'store/actions'
import { connect } from 'react-redux'
import user from 'assets/images/user.png'
import { IoIosArrowDown } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from 'utils/constants'
import Request from 'utils/Request'
import jsCookie from 'js-cookie'

const { Sider } = Layout
const { SubMenu } = Menu
const { Meta } = Card

const SideBar = ({ collapsed, onCollapse, appearanceSetting, width, openKeys, onOpenChange }) => {
  const email = Request.defaults.headers.common['email']
  const permissions = jsCookie.get('permissions')
  const location = useLocation()
  const { t } = useTranslation()
  const logo = appearanceSetting?.media?.logo
    ? `${BASE_URL}${appearanceSetting.media.logo?.url}`
    : defaultlogo

  return (
    <Sider
      // style={{
      //   overflow: 'auto',
      //   height: '100vh',
      //   position: 'fixed',
      //   left: 0,
      //   top: 0,
      //   bottom: 0,
      // }}
      theme="light"
      collapsible={width < 576 ? false : true}
      collapsed={collapsed}
      width={width < 576 ? '100%' : 250}
      breakpoint={width < 576 ? 'none' : 'lg'}
      onCollapse={onCollapse}>
      <div className="top-sidebar">
        <div className="logo">
          <img height={80} src={logo} />
        </div>
        <div className="lang text-center">
          <LanguageMenu />
        </div>
      </div>

      <Dropdown
        trigger="click"
        overlay={
          <Menu className="topbar-menue">
            <Menu.Item>
              <Meta avatar={<Avatar size={45} src={user} />} title={email} />
              <Button type="button" className="btn btn-primary mt-4" onClick={userLogOut}>
                {t('sign out')}
              </Button>
            </Menu.Item>
          </Menu>
        }
        placement="bottomCenter"
        arrow>
        <div className="text-center">
          <div className="user-image">
            <Avatar size={45} src={user} />
          </div>
          <a href="#!" className="sidebar-button">
            <div className="user-info d-flex align-items-center justify-content-center">
              <Meta title={email} className="mr-3" />
              <IoIosArrowDown className="ml-3" />
            </div>
          </a>
        </div>
      </Dropdown>

      <Menu
        theme="light"
        defaultSelectedKeys={['/dashboard']}
        mode="inline"
        className="mt-4"
        selectable={true}
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}>
        {permissions?.includes('dashboard.index') && (
          <Menu.Item key="/dashboard" icon={<DashboardOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/dashboard">{t('dashboard')}</Link>
          </Menu.Item>
        )}

        {permissions?.includes('patients.index') && (
          <Menu.Item key="/patients" icon={<UserAddOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/patients">{t('patients')}</Link>
          </Menu.Item>
        )}

        {permissions?.includes('bookings.index') && (
          <Menu.Item key="/appointments" icon={<CalendarOutlined rotate={90} style={{ fontSize: '23px' }} />}>
            <Link to="/appointments"> {t('appointments')} </Link>
          </Menu.Item>
        )}

        {permissions?.includes('installments.index') && (
          <Menu.Item key="/transactions-history" icon={<DollarOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/transactions-history"> {t('transaction history')} </Link>
          </Menu.Item>
        )}

        {permissions?.includes('statistics') && (
          <Menu.Item key="/statistics" icon={<BarChartOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/statistics">{t('statistics')}</Link>
          </Menu.Item>
        )}

        {/* <Menu.Item key="/packages" icon={<MobileOutlined style={{ fontSize: '23px' }} />}>
          <Link to="#">{t('packages')}</Link>
        </Menu.Item> */}

        {/* {(permissions?.includes('smstemplate.index') || permissions?.includes('sms.index')) && (
          <SubMenu key="sms" icon={<MessageOutlined style={{ fontSize: '23px' }} />} title={t('sms')}>
            {permissions?.includes('smstemplate.index') && (
              <Menu.Item key="/sms/message-forms">
                <Link to="/sms/message-forms"> {t('message forms')} </Link>
              </Menu.Item>
            )}
            {permissions?.includes('sms.index') && (
              <Menu.Item key="/sms/messages">
                <Link to="/sms/messages">{t('messages')}</Link>
              </Menu.Item>
            )}
          </SubMenu>
        )} */}

        {permissions?.includes('sms.index') && (
          <Menu.Item key="/sms/messages" icon={<MessageOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/sms/messages">{t('messages')}</Link>
          </Menu.Item>
        )}

        {permissions?.includes('settings.index') && (
          <SubMenu
            key="configurations"
            icon={<ControlOutlined rotate={90} style={{ fontSize: '23px' }} />}
            title={t('configurations')}>
            <Menu.Item key="/configurations/appointments">
              <Link to="/configurations/appointments"> {t('appointments library')} </Link>
            </Menu.Item>
            <Menu.Item key="/configurations/diagnosis">
              <Link to="/configurations/diagnosis"> {t('diagnosis library')} </Link>
            </Menu.Item>
            <Menu.Item key="/configurations/investigations">
              <Link to="/configurations/investigations"> {t('investigations library')} </Link>
            </Menu.Item>
            <Menu.Item key="/configurations/drugs">
              <Link to="/configurations/drugs"> {t('drugs list')} </Link>
            </Menu.Item>
            <Menu.Item key="/configurations/branches">
              <Link to="/configurations/branches"> {t('branches list')} </Link>
            </Menu.Item>
            <Menu.Item key="/configurations/insurance_companies">
              <Link to="/configurations/insurance_companies"> {t('insurance companies')} </Link>
            </Menu.Item>
          </SubMenu>
        )}

        {permissions?.includes('users.index') && (
          <Menu.Item key="/users" icon={<UserOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/users">{t('users')}</Link>
          </Menu.Item>
        )}

        {permissions?.includes('log.index') && (
          <Menu.Item key="/logs" icon={<ProfileOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/logs">{t('logs')}</Link>
          </Menu.Item>
        )}

        {permissions?.includes('configurations.index') && (
          <Menu.Item key="/appearance" icon={<SettingOutlined style={{ fontSize: '23px' }} />}>
            <Link to="/appearance">{t('appearance')}</Link>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}

SideBar.defaultProps = {
  collapsed: true,
}

SideBar.propTypes = {
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  userLogOut: PropTypes.func.isRequired,
  appearanceSetting: PropTypes.object,
  width: PropTypes.number,
  openKeys: PropTypes.any,
  onOpenChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  appearanceSetting: state.Appearance.singleRow,
})

export default connect(mapStateToProps)(SideBar)
