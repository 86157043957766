import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImPlay2 } from 'react-icons/im'
import { BiSupport } from 'react-icons/bi'
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrTwitter } from 'react-icons/gr'
import { HiSupport } from 'react-icons/hi'

import TechSupport from './TechSupport'

import { Layout, Row, Col } from 'antd'

const { Footer } = Layout

const FooterLayout = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  /* Handle open Tech Support modal */
  const handleOpenTechSupportReq = () => {
    setOpen(true)
  }

  return (
    <Footer className="mt-5">
      <Row className="footer-row">
        <Col xs={24} sm={12} lg={6} xl={6}>
          <div className="tech-supp-library">
            <a
              href="https://best1doc.com/technical-support-library"
              target="_blank"
              rel="noreferrer"
              className="btn btn-secondary ">
              <HiSupport /> <span className="">{t('technical support library')}</span>
            </a>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6} xl={8} xxl={10}>
          <div className="social-links-support">
            <div className="social-links">
              <a
                href="https://www.linkedin.com/company/upper-medic/mycompany/"
                target="_blank"
                rel="noreferrer">
                <GrLinkedinOption />
              </a>
              <a href="https://twitter.com/UpperMedic" target="_blank" rel="noreferrer">
                <GrTwitter />
              </a>
              <a href="https://www.instagram.com/uppermedic_hc/" target="_blank" rel="noreferrer">
                <GrInstagram />
              </a>
              <a href="https://www.facebook.com/UpperMedic/" target="_blank" rel="noreferrer">
                <GrFacebookOption />
              </a>
            </div>
            <button className="btn btn-primary supp-btn" onClick={handleOpenTechSupportReq}>
              <BiSupport /> <span className="px-2">{t('technical support request')}</span>
            </button>
            <button className="btn btn-primary supp-btn-small" onClick={handleOpenTechSupportReq}>
              <BiSupport />
            </button>
          </div>
        </Col>
        <Col xs={24} lg={12} xl={10} xxl={8}>
          <div className="ctreated-by-company  mx-2">
            <span className="py-1 px-2">
              Clinic CRM ©{new Date().getFullYear()} Created by UpperMedic Dev
            </span>
            <button className="btn btn-primary supp-btn" onClick={handleOpenTechSupportReq}>
              <BiSupport />
            </button>
          </div>
        </Col>
      </Row>
      <TechSupport open={open} setOpen={setOpen} />
    </Footer>
  )
}

export default FooterLayout
