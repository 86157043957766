import { SEED_REDUCER_DATA } from './actionTypes'
const initialState = {
  medicalReportModalStatus: false,
  medicalReportModalData: {},
  prescriptionModalStatus: false,
  prescriptionModalData: {},
  invoiceModalStatus: false,
  invoiceModalData: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEED_REDUCER_DATA:
      return { ...status, ...payload }
    default:
      return state
  }
}
