import { GET_TRANSACTIONS, GET_TRANSACTION_ID } from './actionTypes'
import Request from 'utils/Request'
import { redirectUnAuthApi } from 'store/actions'

let urlPath = '/installments'

export const getAllTransactions = (pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath +
            `?page=${pageNo}&patient=${filterData.p_id || ''}&from=${filterData.from || ''}&to=${
              filterData.to || ''
            }`
        : urlPath + `?page=${pageNo}`,
    )
      .then(({ data }) => {
        data.data = data.data.map(item => ({
          ...item,
          type: item.booking?.type,
          total: item.booking?.total,
          installment: item.booking?.total - item?.unpaid,
          date: item.booking?.date,
        }))
        return dispatch({
          type: GET_TRANSACTIONS,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const ViewTransaction = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data }) => {
      return dispatch({
        type: GET_TRANSACTION_ID,
        payload: data.data,
      })
    })
  }
}
