/* eslint-disable no-unused-vars */
import { GET_ALL_PATIENTS, GET_PATIENT_ID } from './actionTypes'
import Request from 'utils/Request'
import History from 'utils/history'
import { HandleSuccessRes, HandleErrorRes, redirectUnAuthApi, HandleValidationsError } from 'store/actions'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
let urlPath = '/patients'

export const getAllPatients = (t, pageNo, filterData) => {
  return dispatch => {
    return Request.get(
      filterData && Object.keys(filterData).length > 0
        ? urlPath +
            `?page=${pageNo}&id=${filterData?.id || ''}&name=${filterData?.name || ''}&phone=${
              filterData?.phone?.split('+').join('') || ''
            }&country=${filterData?.country_id || ''}&city=${filterData?.city_id || ''}&area=${
              filterData?.area_id || ''
            }&gender=${filterData?.gender || ''}`
        : urlPath + `?page=${pageNo}`,
    )
      .then(({ data }) => {
        data.data = data.data.map(item => ({ ...item.personal_information }))

        return dispatch({
          type: GET_ALL_PATIENTS,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        } else {
          // HandleErrorRes('failed', 'Unknown Response')
          HandleValidationsError(t, error?.response?.data?.errors)
        }
      })
  }
}

export const createPatient = (t, payload, quickForm) => {
  return dispatch => {
    return Request.post(urlPath, payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        quickForm === false && History.push('/patients')

        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          HandleValidationsError(t, error.response?.data?.errors)
        } else {
          HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

// export const createQuickPatient = (t, payload) => {
//   return dispatch => {
//     return Request.post(urlPath, payload)
//       .then(({ data }) => {
//         HandleSuccessRes(t('success'), t('successfully added'))
//         return {
//           type: '',
//           payload: data.data,
//         }
//       })
//       .catch(error => {
//         if (error?.response?.status === 422) {
//           HandleValidationsError(t, error.response?.data?.errors)
//         } else {
//           HandleErrorRes(t('failed'), t('add failed'))
//         }
//         return {
//           type: '',
//           payload: '',
//         }
//       })
//   }
// }

export const ViewPatient = id => {
  return dispatch => {
    return Request.get(urlPath + `/${id}`).then(({ data: { data } }) => {
      return dispatch({
        type: GET_PATIENT_ID,
        payload: {
          // medical_information: data.medical_information[0] || {},
          ...data,
          personal_information: data.personal_information,
        },
      })
    })
  }
}

export const editPatient = (t, id, payload) => {
  return dispatch => {
    return Request.post(urlPath + `/${id}`, payload)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully updated'))
        History.push('/patients')
        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          HandleValidationsError(t, error.response?.data?.errors)
        } else {
          HandleErrorRes(t('failed'), t('update failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}

export const deletePatient = (t, id, pageNo, filterData) => {
  return dispatch => {
    return Request.delete(urlPath + `/${id}`)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully deleted'))
        dispatch(getAllPatients(t, pageNo, filterData))
        History.push('/patients')
        return {
          type: '',
          payload: '',
        }
      })
      .catch(error => {
        HandleErrorRes(t('failed'), t('delete failed'))
        return {
          type: '',
          payload: '',
        }
      })
  }
}

/* Handle Delete Data Row */
export const handleDeletePatientRow = (id, t, pageNo, filterData) => {
  return dispatch => {
    Modal.confirm({
      title: t('are you sure delete this'),
      icon: <ExclamationCircleOutlined />,
      content: t('you wont be able to revert this'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        dispatch(deletePatient(t, id, pageNo, filterData))
      },
    })
  }
}

export const uploadPatientFile = (t, fd) => {
  return dispatch => {
    return Request.post(`/file-import`, fd)
      .then(({ data }) => {
        HandleSuccessRes(t('success'), t('successfully added'))
        History.push('/patients')

        return {
          type: '',
          payload: data.data,
        }
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          HandleValidationsError(t, error.response?.data?.errors)
        } else {
          // HandleErrorRes(t('failed'), t('add failed'))
        }
        return {
          type: '',
          payload: '',
        }
      })
  }
}
