/* eslint-disable no-unused-vars */
import { GET_ALL_COUNTRIES, GET_ALL_GOVERNORATES, GET_ALL_AREAS } from './actionTypes'
import Request from 'utils/Request'
import { redirectUnAuthApi } from 'store/actions'

export const getAllCountries = () => {
  return dispatch => {
    return Request.get('/country')
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_COUNTRIES,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const getAllGovernorates = filterData => {
  return dispatch => {
    return Request.get(`/city/${filterData?.country_id}`)
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_GOVERNORATES,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}

export const getAllAreas = filterData => {
  return dispatch => {
    return Request.get(`/area/${filterData?.city_id}`)
      .then(({ data }) => {
        return dispatch({
          type: GET_ALL_AREAS,
          payload: data,
        })
      })
      .catch(error => {
        if (Number(error?.response?.status) == 401) {
          redirectUnAuthApi()
        }
      })
  }
}
