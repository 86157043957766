import React, { useState } from 'react'
import { Form, Modal, Input, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { sendMessageTechSupp } from 'store/actions'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const TechSupport = ({ open, setOpen, sendMessageTechSupp, licensMessage }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [loading, setloading] = useState(false)

  /* Handle Close Modal */
  const handleClose = () => {
    setOpen(false)
    form.resetFields()
  }

  const handleActions = values => {
    setloading(true)
    const messageData =
      t('name') +
      ':' +
      values?.name +
      '\n' +
      t('phone number') +
      ':' +
      values?.phone +
      '\n' +
      '\n' +
      t('request technical support') +
      '\n' +
      '\n' +
      'https://demo.best1doc.com/'

    sendMessageTechSupp(t, { message: messageData }).then(() => {
      setloading(false)
    })
  }

  /* Handle Submit Form */
  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        handleActions(values)
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }

  return (
    <Modal visible={open} onCancel={handleClose} footer={null}>
      <Form form={form} layout="vertical" name="form_in_modal" onFinish={handleSubmit}>
        <Row>
          {licensMessage && (
            <Col xs={24} className="mb-3 text-center px-2">
              <h5>
                <strong style={{ color: '#ee2c2e' }}>{licensMessage}</strong>
              </h5>
            </Col>
          )}
          <Col xs={24} className="mb-3">
            <h6>
              <strong style={{ color: '#666' }}>{t('enter your data and we will call you back')}</strong>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              label={t('name')}
              rules={[
                {
                  required: true,
                  message: t('please enter') + ' ' + t('name'),
                },
              ]}>
              <Input className="form-input" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24} md={12}>
            <Form.Item
              name="phone"
              label={t('mobile number')}
              rules={[
                {
                  required: true,
                  message: t('please enter') + ' ' + t('mobile number'),
                },
                {
                  pattern: '^[ِ+0-9]+$',
                  message: t('accept digits only'),
                },
              ]}>
              <Input placeholder="" className="input-ltr-direction" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              <div className="form-buttons">
                <button disabled={loading} type="submit" className="btn btn-primary">
                  {loading && <LoadingOutlined style={{ color: '#0096a5' }} />}
                  {t('request technical support')}
                </button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

TechSupport.defaultProps = {
  open: false,
}

TechSupport.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  sendMessageTechSupp: PropTypes.func.isRequired,
  licensMessage: PropTypes.string,
}

export default connect(null, { sendMessageTechSupp })(TechSupport)
