export const changeDirictions = (language = 'en') => {
  if (language === 'ar') {
    document.getElementsByTagName('html')[0].setAttribute('lang', 'ar')
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
  } else {
    //en default
    document.getElementsByTagName('html')[0].setAttribute('lang', 'en')
    document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
  }
}
